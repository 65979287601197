import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';

import * as actionTypes from './actionTypes';

firebase.initializeApp({
    apiKey:'AIzaSyCuASI75bdW3LGg443A2NOVh9HBAdx1I0k',
    authDomain: 'visaloyal.firebaseapp.com'
});

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const updateName = (name) => {
    return {
        type: actionTypes.UPDATE_NAME,
        name: name
    };
};

export const fbSuccess = (token,userId) => {
    return dispatch => {
        const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
        localStorage.setItem('token', token);
        localStorage.setItem('expirationDate', expirationDate);
        localStorage.setItem('userId', userId);
        dispatch(authSuccess(token, userId));
        dispatch(checkAuthTimeout(3600));
    }
}

export const authSuccess = (token, userId) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        userId: userId
    };
};

export const authFailSignup = (error) => {
    return {
        type: actionTypes.AUTH_FAIL_SIGNUP,
        error: error
    };
};

export const authFailLogin = (error) => {
    return {
        type: actionTypes.AUTH_FAIL_LOGIN,
        error: error
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    firebase.auth().signOut();
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const authFb = () => {
    console.log(localStorage.getItem('reff'))
    return dispatch => {
        firebase.auth().onAuthStateChanged(user =>{
            if(user){
                const token = user.ya;
                const id = user.uid;
                const instance = axios.create({
                    baseURL: 'https://visaloyal.firebaseio.com/'
                });
                const queryParams = '?auth=' + token + '&orderBy="id"&equalTo="' + id + '"';
                instance.get( '/userDetails.json' + queryParams)
                .then(res=>{
                    if(Object.keys(res.data).length===0){
                        const refId = id.substring(26,27)+id.substring(1,2)+id.substring(15,16)+id.substring(7,8)+id.substring(23,24)+id.substring(11,12)+id.substring(19,20)+id.substring(5,6)+id.substring(17,18)+id.substring(26,27);
                        const userDetails = {id,refId};
                        instance.post( '/userDetails.json?auth=' + token, userDetails )
                    }
                    if(localStorage.getItem('reff')!==''){
                        instance.get( '/userDetails.json')
                        .then(res=>{
                            for(let i in res.data){
                                if(res.data[i].refId===localStorage.getItem('reff')){
                                    instance.post('/userDetails/'+i+'/referrals.json?auth='+token,{id:id})
                                }
                            }
                        });
                    }
                    localStorage.removeItem('reff');
                });
                dispatch(fbSuccess(token, id))
            }
        });
    }
}

export const auth = (fname,lname,email, password,cPassword, isSignup,ref) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password,
            returnSecureToken: true
        };
        let url = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=AIzaSyCuASI75bdW3LGg443A2NOVh9HBAdx1I0k';
        if (!isSignup) {
            url = 'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyCuASI75bdW3LGg443A2NOVh9HBAdx1I0k';
        };
        const updateProfile = 'https://identitytoolkit.googleapis.com/v1/accounts:update?key=AIzaSyCuASI75bdW3LGg443A2NOVh9HBAdx1I0k';
        const verify = 'https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode?key=AIzaSyCuASI75bdW3LGg443A2NOVh9HBAdx1I0k';

        const instance = axios.create({
            baseURL: 'https://visaloyal.firebaseio.com/'
        });
        if(password!==cPassword){
            dispatch(authFailLogin({message:'*Passwords do not match'}))
        } else {
            axios.post(url, authData)
                .then(response => {
                    const id = response.data.localId;
                    const token = response.data.idToken;
                    if(isSignup){
                        if(ref!==''){
                            instance.get( '/userDetails.json')
                            .then(res=>{
                                for(let i in res.data){
                                    if(res.data[i].refId===ref){
                                        instance.post('/userDetails/'+i+'/referrals.json?auth='+token,{id:id});
                                    }
                                }
                            });
                        }
                        const refId = response.data.localId.substring(26,27)+response.data.localId.substring(1,2)+response.data.localId.substring(15,16)+response.data.localId.substring(7,8)+response.data.localId.substring(23,24)+response.data.localId.substring(11,12)+response.data.localId.substring(19,20)+response.data.localId.substring(5,6)+response.data.localId.substring(17,18)+response.data.localId.substring(26,27);
                        const userDetails = {id,refId};
                        instance.post( '/userDetails.json?auth=' + token, userDetails );
                        localStorage.setItem('userId', id);
                        dispatch(authSuccess(null, id));
                        const profile = {
                            idToken : token,
                            displayName : fname+' '+lname,
                            photoUrl : null,
                            deleteAttribute : [],
                            returnSecureToken : false
                        }
                        axios.post(updateProfile, profile);
                        axios.post(verify,{requestType:'VERIFY_EMAIL',idToken : token})
                    } else {
                        const expirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000);
                        localStorage.setItem('token', response.data.idToken);
                        localStorage.setItem('expirationDate', expirationDate);
                        localStorage.setItem('userId', response.data.localId);
                        dispatch(authSuccess(response.data.idToken, response.data.localId));
                        dispatch(checkAuthTimeout(response.data.expiresIn));
                    }
                })
                .catch(err => {
                    const msg = err.response.data.error.message
                    if(msg === 'INVALID_PASSWORD'){
                        err.response.data.error.message = '*You have entered an invalid password'
                    }else if(msg === 'EMAIL_NOT_FOUND'){
                        err.response.data.error.message = '*This email is not registered with us'
                    }else if(msg === 'EMAIL_EXISTS'){
                        err.response.data.error.message = '*This email is already registered with us'
                    }
                    if(isSignup){
                        dispatch(authFailSignup(err.response.data.error));
                    } else {
                        dispatch(authFailLogin(err.response.data.error));
                    }
                });
        };
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if(token){
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
            }   
        }
            
    };
};