import * as actionTypes from '../actions/actionTypes';

const initialState = {
    token: null,
    userId: null,
    loginError: null,
    signupError: null,
    error: null
};

const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

const authStart = ( state ) => {
    return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
    return updateObject( state, { 
        token: action.idToken,
        userId: action.userId,
        error: null,
     } );
};

const fbSuccess = (state,action) => {
    return updateObject( state, { 
        token: action.idToken,
        userId: action.userId,
        error: null,
     } );
};

const authFailSignup = (state, action) => {
    return updateObject( state, {
        signupError: action.error,
    });
};

const authFailLogin = (state, action) => {
    return updateObject( state, {
        loginError: action.error,
    });
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
    });
};

const authLogout = (state) => {
    return updateObject(state, { token: null, userId: null });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.FB_SUCCESS: return fbSuccess(state,action);
        case actionTypes.AUTH_FAIL_LOGIN: return authFailLogin(state, action);
        case actionTypes.AUTH_FAIL_SIGNUP: return authFailSignup(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        default:
            return state;
    }
};

export default reducer;