import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, BrowserRouter as Router, withRouter } from 'react-router-dom';
import Layout from './components/Layout/';
import * as actions from './store/actions/index';

// Import Css
import './Apps.scss';
import './assets/css/materialdesignicons.min.css';
import './assets/css/colors/default.css';

// Include Routes 
import routes from './routes';

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {  
    render() {
      return <Layout>
        <WrappedComponent></WrappedComponent>
      </Layout>
    }
  };
}

class App extends Component {

  Loader = () => {
    return (
      <div id="preloader">
          <div id="status">
              <div className="spinner">
                  <div className="double-bounce1"></div>
                  <div className="double-bounce2"></div>
              </div>
          </div>
      </div>
    );
  };

  componentDidMount () {
    this.props.onTryAutoSignup();
  }

  render() {
    return (
      <React.Fragment>  
      <Router>
        <Suspense fallback = {this.Loader()} >
            <Switch>
            {routes.map((route, idx) =>
                route.isWithoutLayout ?
                  <Route path={route.path} exact={route.exact} component={route.component} key={idx} />
                :
                  <Route path={route.path} exact component={withLayout(route.component)} key={idx} />
            )}
            </Switch>
            </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    name: state.auth.name
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState() ),
    fbSuccess: (token,userId) => dispatch(actions.fbSuccess(token,userId))
  };
};

export default withRouter(connect( mapStateToProps, mapDispatchToProps )( App ) );