import React from 'react';

// Root Include
const Root = React.lazy(() => import('./pages/Services/index'));

//Main Index
const Main = React.lazy(() => import('./pages/Home/indexMain'));

//Special
const PageError = React.lazy(() => import('./pages/Pages/Special/PageError'));
const PageMaintenance = React.lazy(() => import('./pages/Pages/Special/PageMaintenance'));

//Auth Pages
const PageLogin = React.lazy(() => import('./pages/Pages/AuthPages/PageLogin'));

const PageSignup = React.lazy(() => import('./pages/Pages/AuthPages/PageSignup'));

const PageRecoveryPassword = React.lazy(() => import('./pages/Pages/AuthPages/PageRecoveryPassword'));

// Import all components
const Services = React.lazy(() => import('./pages/Services/index'));;

//Docs
const ChangeLog = React.lazy(() => import('./pages/Docs/ChangeLog'));
const Components = React.lazy(() => import('./pages/Docs/Components'));
const Documentation = React.lazy(() => import('./pages/Docs/Documentation'));
const Widget = React.lazy(() => import('./pages/Docs/widget'));

const PageAboutUs = React.lazy(() => import('./pages/Pages/PageAboutUs'));
const PagePricing = React.lazy(() => import('./pages/Pages/PagePricing'));
const PageServices = React.lazy(() => import('./pages/Pages/PageServices'));
const PageTeam = React.lazy(() => import('./pages/Pages/PageTeam'));

//Account
const PageProfile = React.lazy(() => import('./pages/Pages/Account/PageProfile'));
const PageProfileEdit = React.lazy(() => import('./pages/Pages/Account/PageProfileEdit'));
const PageInvoice = React.lazy(() => import('./pages/Pages/Account/PageInvoice'));

//Career
const PageJobsSidebar = React.lazy(() => import('./pages/Pages/Careers/PageJobsSidebar'));
const PageJobDetail = React.lazy(() => import('./pages/Pages/Careers/PageJobDetail'));
const PageJob = React.lazy(() => import('./pages/Pages/Careers/PageJob'));
const PageJobApply = React.lazy(() => import('./pages/Pages/Careers/PageJobApply'));
const PageJobCompany = React.lazy(() => import('./pages/Pages/Careers/PageJobCompany'));
const PageJobCandidate = React.lazy(() => import('./pages/Pages/Careers/PageJobCandidate'));

//Blog
const PageBlog = React.lazy(() => import('./pages/Pages/Blog/PageBlog'));
const PageBlogDetail = React.lazy(() => import('./pages/Pages/Blog/PageBlogDetail'));


//Utility
const PagePrivacy = React.lazy(() => import('./pages/Pages/Utility/PagePrivacy'));
const PageTerms = React.lazy(() => import('./pages/Pages/Utility/PageTerms'));

//Contact
const PageContactThree = React.lazy(() => import('./pages/Pages/Contact/PageContactThree'));

//Upload
const Upload = React.lazy(() => import('./pages/Pages/Upload'));

//Email
const EmailAlert = React.lazy(() => import('./pages/Pages/EmailTemplate/EmailAlert'));
const EmailPasswordReset = React.lazy(() => import('./pages/Pages/EmailTemplate/EmailPasswordReset'));
const EmailConfirmation = React.lazy(() => import('./pages/Pages/EmailTemplate/EmailConfirmation'));
const EmailInvoice = React.lazy(() => import('./pages/Pages/EmailTemplate/EmailInvoice'));

//Help Center
const HelpCenterOverview = React.lazy(() => import('./pages/Pages/HelpCenter/HelpCenterOverview'));
const HelpCenterFaqs = React.lazy(() => import('./pages/Pages/HelpCenter/HelpCenterFaqs'));
const HelpCenterGuides = React.lazy(() => import('./pages/Pages/HelpCenter/HelpCenterGuides'));
const HelpCenterSupportRequest = React.lazy(() => import('./pages/Pages/HelpCenter/HelpCenterSupportRequest'));

//Backend
//Contact 
const Contact = React.lazy(() => import('./'));

const routes = [
    //routes without Layout

    //Contct without layout

    //Email Pages
    { path: '/php', component: Contact, isWithoutLayout : true },
    { path: '/email-alert', component: EmailAlert, isWithoutLayout : true },
    { path: '/password-reset', component: EmailPasswordReset, isWithoutLayout : true },
    { path: '/email-confirmation', component: EmailConfirmation, isWithoutLayout : true },
    { path: '/email-invoice', component: EmailInvoice, isWithoutLayout : true },

    //Special Pages
    { path: '/page-error', component: PageError, isWithoutLayout : true },
    { path: '/page-maintenance', component: PageMaintenance, isWithoutLayout : true },

    //User Pages
    { path: '/login', component: PageLogin, isWithoutLayout : true },
    { path: '/signup', component: PageSignup, isWithoutLayout : true },
    { path: '/re-password', component: PageRecoveryPassword, isWithoutLayout : true },
    
    // Landings
    { path: '/services', component: Services },
    { path: '/how', component: PageServices },
    { path: '/aboutus', component: PageAboutUs },
    { path: '/pricing', component: PagePricing },
    { path: '/team', component: PageTeam },

    //Help Center
    { path: '/helpcenter-overview', component: HelpCenterOverview },
    { path: '/helpcenter-faqs', component: HelpCenterFaqs },
    { path: '/helpcenter-guides', component: HelpCenterGuides },
    { path: '/helpcenter-support-request', component: HelpCenterSupportRequest },

    //Utility
    { path: '/terms', component: PageTerms },
    { path: '/privacy', component: PagePrivacy },

    //Page Profile
    { path: '/profile', component: PageProfile },
    { path: '/profile-edit', component: PageProfileEdit },
    { path: '/referral', component: PageInvoice },

    //Page Job
    { path: '/page-job', component: PageJob },
    { path: '/page-job-apply', component: PageJobApply },
    { path: '/page-job-detail', component: PageJobDetail },
    { path: '/page-jobs-sidebar', component: PageJobsSidebar },
    { path: '/page-job-company', component: PageJobCompany },
    { path: '/page-job-candidate', component: PageJobCandidate },

    //Page Blog
    { path: '/page-blog-grid', component: PageBlog },
    { path: '/page-blog-detail', component: PageBlogDetail },

    //Page Contact
    { path: '/contact', component: PageContactThree },

    //Page Upload
    { path: '/upload', component: Upload},

    //Docs
    { path: '/changelog', component: ChangeLog },
    { path: '/components', component: Components },
    { path: '/documentation', component: Documentation },
    { path: '/widget', component: Widget },
    
    //Index Main
    { path: '/index', component: Main },

    //Index root
    
    { path: '/', component: Root}, 
    { component: PageError, isWithoutLayout : true, exact : false },
    
];

export default routes;